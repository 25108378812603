import axios, { AxiosError } from 'axios'
import Env from '../../../../utils/env'
import { disconnectSocket } from './socket'
import { toast } from 'react-toastify'
import i18n from 'i18next'

export const api = axios.create({
  baseURL: `${Env.backend}`
})

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const { status, config } = error.response || {}
    const requestUrl = config?.url

    if (status === 401) {
      if (requestUrl !== 'login') {
        window.location.href = '/login'
        localStorage.removeItem('@realize: accessToken')
        localStorage.removeItem('@realize: user')
        localStorage.removeItem('@realize: currentPage')
        disconnectSocket()
        toast.error(
          i18n.t(
            'Your connection was opened in another browser, if not, please contact support.'
          )
        )
      }
    }

    return Promise.reject(error)
  }
)

export default api
