import React from 'react'
import * as S from './styles'

const Loading = () => {
  return (
    <S.Wrapper>
      <h6>Realize</h6>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          background: 'none',
          display: 'block',
          shapeRendering: 'auto'
        }}
        width="57px"
        height="57px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="32" cy="50" fill="#e3566b" r="18">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.0204081632653061s"
            keyTimes="0;0.5;1"
            values="32;68;32"
            begin="-0.5102040816326531s"
          ></animate>
        </circle>
        <circle cx="68" cy="50" fill="#1e2e5f" r="18">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.0204081632653061s"
            keyTimes="0;0.5;1"
            values="32;68;32"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="32" cy="50" fill="#e3566b" r="18">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1.0204081632653061s"
            keyTimes="0;0.5;1"
            values="32;68;32"
            begin="-0.5102040816326531s"
          ></animate>
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            dur="1.0204081632653061s"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
    </S.Wrapper>
  )
}

export default Loading
