/* eslint-disable @typescript-eslint/no-explicit-any */
import { io, Socket } from 'socket.io-client'
import Env from '../../../../utils/env'
import { toast } from 'react-toastify'
export let socket: Socket

export const initiateSocket = (
  id: string,
  name: string,
  avatar: any,
  signOut: () => void
) => {
  console.log('Creating socket connection...')
  socket = io(`${Env.socketserver || Env.backend}/auth`, {
    transports: ['websocket'],
    forceNew: true,
    multiplex: false,
    query: { id, name, avatar }
  })
  socket.on('logout', () => {
    signOut()
    toast.error(
      'Seu acesso foi usado em outro navegador. Caso não seja você, por gentileza, acione o suporte.'
    )
  })
  return socket
}

export const disconnectSocket = () => {
  console.log('Disconnecting socket...')
  if (socket) socket.disconnect()
}

interface ISubscribeConversionData {
  data: {
    unitId: string
    s3Url: string
  }
}

export const onSubscribeConversion = (
  cb: ({ data: { s3Url, unitId } }: ISubscribeConversionData) => void
) => {
  socket.on('convert_ended', (data: ISubscribeConversionData) => {
    return cb(data)
  })
}

export const offSubscribeConversion = () => {
  socket.off('convert_ended')
}
