interface IDsn {
  [key: string]: string
}

const dsn: IDsn = {
  'refactor.realize.pro.br':
    'https://6b458de0afc849d9b3d4b9353a297a55@o361011.ingest.sentry.io/5909809'
}

export default dsn
