import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router'
import * as Sentry from '@sentry/react'
import { SWRConfig } from 'swr'
import hotjar from './config/hotjar'
import { fetcher } from '@api/swr'
import { routes } from './routes/config'
import Router from './routes/Router'
import { AuthProvider } from './contexts/auth-context'
import './global.css'
import dsn from './sentry'
import React from 'react'
import packageJson from '../package.json'

const location = window.location.hostname

if (!location.includes('localhost')) {
  Sentry.init({
    dsn: dsn['refactor.realize.pro.br'],
    release: 'version-' + packageJson.version,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 0.1
  })
}

function App() {
  hotjar()

  return (
    <AuthProvider>
      <SWRConfig
        value={{
          fetcher,
          // revalidateOnReconnect: false,
          revalidateOnFocus: false
        }}
      >
        <BrowserRouter>
          <Router routes={routes} />
        </BrowserRouter>
        <ToastContainer />
      </SWRConfig>
    </AuthProvider>
  )
}
export default App
