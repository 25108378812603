export default {
  grid: {
    container: '130rem',
    gutter: '3.2rem'
  },
  border: {
    radius: '0.8rem'
  },
  font: {
    family:
      "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    light: 300,
    normal: 400,
    bold: 600,
    sizes: {
      xxsmall: '1rem',
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
      huge: '5.2rem',
      fontHeading: '3.0rem',
      h6: '20px'
    }
  },
  colors: {
    primary: '#E3566B',
    primary100: '#FEE7DF',
    primary200: '#FDCBC0',
    primary300: '#F9A79F',
    primary400: '#F48687',
    primary500: '#EE5F6D',
    primary600: '#CC455F',
    primary700: '#AB2F52',
    primary800: '#8A1E46',
    primary900: '#72123E',
    secondary: '#424C5B',
    secondary100: '#E9F0F6',
    secondary200: '#D4E2EE',
    secondary300: '#ABBCCD',
    secondary400: '#7C8A9C',
    secondary500: '#424C5B',
    secondary600: '#303B4E',
    secondary700: '#212B41',
    secondary800: '#151D34',
    secondary900: '#0C142B',
    mainBg: '#424C5B',
    lightBg: '#F3F3F5',
    white: '#FFFFFF',
    black: '#030517',
    lightGray: '#EAEAEA',
    placeholder: '#BBBBBB',
    gray100: '#FAFAFA',
    gray200: '#F2F2F2',
    gray300: '#D7D7D7',
    gray400: '#949494',
    gray500: '#4E4E4E',
    gray600: '#434343',
    gray700: '#383838',
    gray800: '#2d2d2d',
    gray900: '#252525',
    Danger100: '#FBE3D7',
    Danger200: '#F8C3B0',
    Danger300: '#EB9684',
    Danger400: '#D86C62',
    Danger500: '#BF3434',
    Danger600: '#A42630',
    Danger700: '#891A2D',
    Danger800: '#6E1029',
    Danger900: '#5B0926',
    colorLink: '#A3A3A3',
    gray: '#8F8F8F',
    warning200: '#FAEBA1',
    warning500: '#CEA317',
    darkGray: '#383838',
    fontGray: '#73787D',
    borderInputColor: '#7C8A9C',
    borderCheckBox: '#D0D6DC',
    iconUnchecked: '#4E4E4E',
    selectGray: '#434343',
    red: '#FF6347',
    success100: '#DEF9D4',
    success200: '#B8F4AB',
    success300: '#83E07C',
    success400: '#55C156',
    success500: '#279933',
    success600: '#1C8330',
    success700: '#136E2D',
    success800: '#0C5829',
    success900: '#074926',
    bgGradient: 'linear-gradient(147.81deg, #DD6770 0%, #B34263 72.61%)'
  },
  hover: {
    primaryHover: '#C74357',
    secundaryHover: '#2C3645',
    pressed: {
      secundaryHover: '#232C38',
      primaryPressed: '#983543'
    },
    disabled: {
      primaryDisabled: '#FFBDC7',
      secundaryHover: '#BFC7D2'
    }
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  transition: {
    default: '0.4s ease-in-out',
    fast: '0.2s ease-in-out'
  }
}
