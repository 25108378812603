import { hotjar } from 'react-hotjar'

export const config: { [key: string]: { hjid: number; hjsv: number } } = {
  'dfsystem.realize.pro.br': {
    hjid: 2571324,
    hjsv: 6
  },
  'platos.realize.pro.br': {
    hjid: 2551615,
    hjsv: 6
  },
  'refactor.realize.pro.br': {
    hjid: 2558499,
    hjsv: 6
  }
}

const initializeHotJar = () => {
  const hotjarConfig = config[window.location.hostname]
  if (hotjarConfig) {
    const { hjid, hjsv } = hotjarConfig
    hotjar.initialize(hjid, hjsv)
  }
}

export default initializeHotJar
