import { disconnectSocket, initiateSocket } from '@api/socket'
import { toast } from 'react-toastify'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import {
  IAuthContextData,
  IAuthProvider,
  IAuthState
} from '@domain/Authentication'
import api from '@api/index'
import i18n from 'i18next'

export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData
)

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [data, setData] = useState<IAuthState>({} as IAuthState)
  const [permissions, setPermissions] = useState<string[]>([])

  useEffect(() => {
    const accessToken = localStorage.getItem('@realize: accessToken')
    const storageDatas = localStorage.getItem('@realize: user')

    if (accessToken && storageDatas) {
      const parseData = JSON.parse(storageDatas)
      const user = {
        id: parseData.id,
        name: parseData.name,
        role: parseData.role,
        avatar: parseData.avatar,
        settings: {
          receiveEmails: parseData.settings
            ? parseData.settings.receiveEmails
            : false
        }
      }
      api.defaults.headers.authorization = `Bearer ${accessToken}`
      setData({ accessToken, user })
    }
  }, [])

  useEffect(() => {
    if (data && data.user && data.user.id) {
      api.get(`permission`).then((response) => {
        setPermissions(response.data)
      })
    }
  }, [data])

  const singOut = useCallback(() => {
    localStorage.removeItem('@realize: accessToken')
    localStorage.removeItem('@realize: user')
    localStorage.removeItem('@realize: currentPage')
    localStorage.removeItem('@realize: currentPath')
    disconnectSocket()

    setData({} as IAuthState)
    window.location.href = '/login'
  }, [])

  const socketConnection = useMemo(() => {
    if (data && data.user) {
      console.log('Creating a new socket')
      const { id, name, avatar } = data.user
      return initiateSocket(id, name, avatar, singOut)
    }
  }, [data, singOut])

  const singIn = useCallback(async ({ email, password }) => {
    try {
      const response = await api
        .post('login', {
          email,
          password
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            toast.error(i18n.t('Incorrect password and / or email'))
          } else {
            toast.error(
              i18n.t(
                'The system appears to be unavailable. Please try again in a few moments.'
              )
            )
          }
        })

      const { id, accessToken, name, role, avatar, settings } = response.data

      localStorage.setItem('@realize: accessToken', accessToken)
      localStorage.setItem(
        '@realize: user',
        JSON.stringify({
          name,
          role,
          id,
          avatar,
          settings: {
            receiveEmails: settings.receiveEmails
          }
        })
      )

      api.defaults.headers.authorization = `Bearer ${accessToken}`

      setData({
        accessToken,
        user: {
          name,
          role,
          id,
          avatar,
          settings: {
            receiveEmails: settings.receiveEmails
          }
        }
      })
    } catch (error: unknown) {
      console.log(error)
    }
  }, [])

  const updateAvatar = useCallback(
    (avatar: string) => {
      if (data) {
        localStorage.setItem(
          '@realize: user',
          JSON.stringify({ ...data.user, avatar: avatar })
        )
        setData({ ...data, user: { ...data.user, avatar } })
      }
    },
    [data]
  )

  const updateReceiveEmails = useCallback(
    (receiveEmails: boolean) => {
      if (data) {
        localStorage.setItem(
          '@realize: user',
          JSON.stringify({
            ...data.user,
            settings: { receiveEmails: receiveEmails }
          })
        )
        setData({
          ...data,
          user: { ...data.user, settings: { receiveEmails: receiveEmails } }
        })
      }
    },
    [data]
  )

  const isContentist = useCallback(() => {
    if (data && data.user) {
      return data.user.role === 'content'
    }

    return false
  }, [data])

  useEffect(() => {
    if (socketConnection) {
      socketConnection.on('system_updating', () => {
        toast.info('System entering maintenance')
      })
      socketConnection.on('system_updated', () => {
        toast.success('The system was successfully updated!')
      })
      socketConnection.on('admin_message', (message) => {
        toast.info(`${message}`)
      })
      socketConnection.on('convert_ended', () => {
        console.log('converted')
      })
      socketConnection.on('kick_user', () => {
        singOut()
      })
    }
  }, [singOut, socketConnection])

  return (
    <AuthContext.Provider
      value={{
        singIn,
        user: data.user,
        isContentist,
        socket: socketConnection,
        singOut,
        permissions,
        updateAvatar,
        updateReceiveEmails
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
